<template>
  <proxy-login
    :employee-id.sync="employeeIdData"
    :employee-name.sync="employeeNameData"
    :employee-class-items="employeeClassItemsData"
    :employee-class.sync="employeeClassData"
    :version="version"
    :error-messages="errorMessagesData"
    :employee-search-dialog-show="showSearchDialogData"
    @employee-search-btn-click="employeeSearchBtnClickEvent"
    @employee-search-dialog-ok-btn-click="employeeSearchDialogOKBtnClickEvent"
    @employee-search-dialog-cancel-btn-click="
      employeeSearchDialogCancelBtnClickEvent
    "
    @login-btn-click="loginBtnClickEvent"
    @logout-btn-click="logoutBtnClickEvent"
  ></proxy-login>
</template>

<script>
  import proxyLogin from "@/components/templates/login/proxyLogin.vue";
  import typeHelper from "@/common/type.js";
  import { APP } from "@/common/const.js";
  export default {
    components: {
      proxyLogin,
    },
    data() {
      return {
        employeeIdData: null,
        employeeNameData: null,
        employeeNoData: null,
        employeeClassItemsData: [],
        employeeClassData: null,

        errorMessagesData: [],
        showSearchDialogData: false,
      };
    },
    props: {
      version: {
        type: String,
        default: null,
      },
    },
    computed: {},
    methods: {
      employeeSearchBtnClickEvent() {
        this.showSearchDialogData = true;
      },
      employeeSearchDialogOKBtnClickEvent(item) {
        this.employeeIdData = item.value;
        this.employeeNameData = item.text;
        this.employeeNoData = item.no;
        this.showSearchDialogData = false;
      },
      employeeSearchDialogCancelBtnClickEvent() {
        this.showSearchDialogData = false;
      },
      logoutBtnClickEvent() {
        this.axios
          .delete("/api/proxy-login-mode")
          .then((res) => {
            window.location.replace(APP.HOME_URL);
          })
          .catch((err) => {
            if (err.apiErrorMessages) {
              this.errorMessagesData = err.apiErrorMessages;
            } else {
              throw err;
            }
          });
      },
      loginBtnClickEvent() {
        let req = {
          no: this.employeeNoData,
        };
        this.axios
          .post("/api/login", req)
          .then((res) => {
            if (typeHelper.isNull(this.$route.query.redirect)) {
              window.location.replace(APP.HOME_URL);
            } else {
              window.location.replace(
                decodeURIComponent(this.$route.query.redirect)
              );
            }
          })
          .catch((err) => {
            if (err.apiErrorMessages) {
              this.errorMessagesData = err.apiErrorMessages;
            } else {
              throw err;
            }
          });
      },
      axiosError(err) {
        let errors = [];
        if (err.response.status === 422) {
          _.forEach(err.response.data.errors, (value, key) => {
            errors = _.concat(errors, value);
          });
        } else if (_.has(err, "response.data.error_description")) {
          errors = _.concat(errors, err.response.data.error_description);
        } else {
          throw err;
        }
        this.setErrorMessage(errors);
      },
      async getSelectItems() {
        // 外部項目の取得
        await this.axios
          .get("/api/employees/proxy-select-items")
          .then((res) => {
            this.employeeClassItemsData = res.data.employeeClasses;
            this.employeeClassData = res.data.employeeClass;
          })
          .catch((err) => {
              if (err.apiErrorMessages) {
                this.errorMessagesData = err.apiErrorMessages;
              } else {
                throw err;
              }
          });
      },
      setErrorMessage(value) {
        this.errorMessagesData = value;
      },
    },
    created: function () {
      this.$emit("show-menu", false);
    },
    mounted() {
      // 外部項目の取得
      this.getSelectItems();
    },
    destroyed: function () {
      this.$emit("show-menu", true);
    },
  };
</script>
