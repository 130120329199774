<template>
  <page-layout class="text-tablet">
    <template v-slot:title>
      <v-row justify="end">
        <icon-label-btn
          btn-text="管理者ログインへ"
          type="logoutText"
          @btnClick="$emit('logout-btn-click')"
          small
          text-position="left"
        >
        </icon-label-btn>
      </v-row>
    </template>
    <template v-slot:contents>
      <v-row>
        <v-col cols="12" class="pt-10">
          <v-row justify="center" align="center">
            <div>
              <proxy-login-form
                class="login-login-form"
                :employee-id.sync="_employeeId"
                :employee-name.sync="_employeeName"
                :employee-class-items="employeeClassItems"
                :employee-class.sync="_employeeClass"
                @employee-search-btn-click="$emit('employee-search-btn-click')"
                @login-btn-click="$emit('login-btn-click')"
              ></proxy-login-form>
              <div class="text-end subtitle-1"> Ver. {{ version }} </div>
            </div>
          </v-row>
          <v-row justify="center" align="center">
            <error-message-panel
              title=""
              :messages="errorMessages"
              class="mb-1 mt-2"
              v-if="errorMessages.length > 0"
            />
          </v-row>
        </v-col>
      </v-row>
      <employee-search-dialog
        v-if="employeeSearchDialogShow"
        :show="employeeSearchDialogShow"
        :employee-class="_employeeClass"
        :end-date="endDateData"
        proxy
        @ok-btn-click="$emit('employee-search-dialog-ok-btn-click', $event)"
        @cancel-btn-click="$emit('employee-search-dialog-cancel-btn-click')"
        tablet
      />
    </template>
  </page-layout>
</template>

<script>
  import pageLayout from "@/components/templates/layout/pageLayout.vue";
  import proxyLoginForm from "@/components/organisms/login/proxyLoginForm.vue";
  import errorMessagePanel from "@/components/molecules/panel/errorMessagePanel.vue";
  import iconLabelBtn from "@/components/atoms/button/iconLabelBtn.vue";
  import employeeSearchDialog from "@/components/organisms/searchDialog/employeeSearchDialog.vue";
  export default {
    components: {
      pageLayout,
      proxyLoginForm,
      errorMessagePanel,
      iconLabelBtn,
      employeeSearchDialog,
    },
    data() {
      return {
        endDateData: new Date(),
      };
    },
    props: {
      employeeId: {
        type: String,
        default: "",
      },
      employeeName: {
        type: String,
        default: "",
      },
      employeeClassItems: {
        type: Array,
        default: function () {
          return [];
        },
      },
      employeeClass: {
        type: String,
        default: null,
      },
      errorMessages: {
        type: Array,
        default: function () {
          return [];
        },
      },
      employeeSearchDialogShow: {
        type: Boolean,
        default: false,
      },
      version: {
        type: String,
        default: null,
      },
    },
    computed: {
      _employeeId: {
        get() {
          return this.employeeId;
        },
        set(value) {
          this.$emit("update:employeeId", value);
        },
      },
      _employeeName: {
        get() {
          return this.employeeName;
        },
        set(value) {
          this.$emit("update:employeeName", value);
        },
      },
      _employeeClass: {
        get() {
          return this.employeeClass;
        },
        set(value) {
          this.$emit("update:employeeClass", value);
        },
      },
    },
    methods: {},
  };
</script>

<style scoped>
  .login-login-form {
    width: 500px;
  }
</style>
